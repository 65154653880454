import React from "react";
import { FaCcStripe } from "react-icons/fa";
import "../../style/admin.css";
import { AuthenticateStripe, RenewToken } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import { DecryptedDataAdminlocalStorage } from "../../Components/Common/Common";

const SetupStripe = () => {
  const dispatch = useDispatch();

  const handleRenewToken = async () => {
    const email = DecryptedDataAdminlocalStorage(localStorage?.getItem("email"));
    const reference_number = DecryptedDataAdminlocalStorage(localStorage?.getItem("reference_number"));

    try {
      const result = await dispatch(RenewToken({ email, reference_number }));
      if (result?.success) {
        console.success("Token renewed successfully");
      }
    } catch (error) {
      console.error("Failed to renew token");
    }
  };

  const handleConnectToAuthenticate = async () => {
    const email = DecryptedDataAdminlocalStorage(localStorage?.getItem("email"));
    const reference_number = DecryptedDataAdminlocalStorage(localStorage?.getItem("reference_number"));

    const authenticateStripeAction = AuthenticateStripe({ email, reference_number, dispatch });
    await authenticateStripeAction();
  };

  return (
    <div
      className="setup-stripe-container"
      style={{
        width: "100%",
        maxWidth: "580px",
      }}
    >
      <div className="form-css">
        <div className="stripe-logo">
          <FaCcStripe size={95} className="stripe_logo_css" />
        </div>

        <div className="w-100 py-3">
          <div className="button-container">
            <button className="btn form-button connect-button" onClick={handleConnectToAuthenticate}>
              Connect to Authenticate
            </button>

            <button className="btn form-button Renew-button" onClick={handleRenewToken}>
              Renew Token
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupStripe;
