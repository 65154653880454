import React, { useState, useEffect } from "react";
import { Card, Button, Tabs, Modal, Form, Input, InputNumber, message } from "antd";
import { CrownFilled, StarFilled, RocketFilled, ThunderboltFilled, EyeInvisibleFilled } from "@ant-design/icons";
import "../../style/admin.css";
import { useDispatch, useSelector } from "react-redux";
import { CreateSubscriptionPlan, DeleteSubscriptionPlan, GetSubscriptionPlans, UpdateSubscriptionPlan } from "../../redux/auth/authSlice";
import TabPane from "antd/es/tabs/TabPane";

const { Meta } = Card;

const Subscription = () => {
  const [showBlackCard, setShowBlackCard] = useState(false);
  const [selectedTab, setSelectedTab] = useState("monthly");
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const dispatch = useDispatch();
  const { subscriptionPlans } = useSelector((state) => state.counter);

  const [editPlan, setEditPlan] = useState(null);

  useEffect(() => {
    dispatch(GetSubscriptionPlans({ dispatch }));
  }, [dispatch]);

  const handleEditClick = (plan) => {

    
    setEditPlan(plan);
    setIsEditModalVisible(true);
    editForm.setFieldsValue({
      reference_number: plan.reference_number,
      name: plan.name,
      monthly_cost: plan.monthly_cost,
      yearly_cost: plan.yearly_cost,
      campaign_specific_price: plan.campaign_specific_price,
      entry: plan.entry,
      benefits: plan.benefits,
      credits_per_action: plan.credits_per_action,
    });
  };

  const handleEditSubmit = async (values) => {


    
    try {
      const isNameConflict = subscriptionPlans.some((plan) => plan.name.toLowerCase() === values.name.toLowerCase() && plan.reference_number !== values.reference_number);

      if (isNameConflict) {
        message.error("A plan with this name already exists. Please choose a different name.");
        return;
      }

      const payload = {
        reference_number: values.reference_number,
        name: values.name,
        monthly_cost: values.monthly_cost,
        yearly_cost: values.yearly_cost,
        campaign_specific_price: "CAMPAIGN SPECIFIC PRICE Monthly/Yearly",
        entry: values.entry,
        benefits: values.benefits,
        credits_per_action: "2",
      };
      
      setIsEditModalVisible(false);
      await dispatch(UpdateSubscriptionPlan({ obj: payload, dispatch })).unwrap();

      dispatch(GetSubscriptionPlans({ dispatch }));
    } catch (error) {
      console.error("Failed to update plan:", error);
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalVisible(false);
    setEditPlan(null);
    editForm.resetFields();
  };

  const handleDeleteClick = (referenceNumber) => {
    setPlanToDelete(referenceNumber);
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    if (planToDelete) {
      try {
        await dispatch(DeleteSubscriptionPlan({ subscriptionTierReferenceNumber: planToDelete, dispatch })).unwrap();
        dispatch(GetSubscriptionPlans({ dispatch }));
      } catch (error) {
      } finally {
        setIsDeleteModalVisible(false);
        setPlanToDelete(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
    setPlanToDelete(null);
  };



  const getCardColor = (planName) => {
    switch (planName.toLowerCase()) {
      case "bronze tier":
        return "linear-gradient(135deg, #cd7f32, #a66a2a)";
      case "silver tier":
        return "linear-gradient(135deg, #c0c0c0, #a0a0a0)";
      case "gold tier":
        return "linear-gradient(135deg, #ffd700, #d4af37)";
      case "platinum":
        return "linear-gradient(135deg, #e5e4e2, #d3d3d3)";
      case "black":
        return "linear-gradient(135deg, #000000, #333333)";
      default:
        return "linear-gradient(135deg, #000000, #333333)";
    }
  };

  const getCardIcon = (planName) => {
    switch (planName.toLowerCase()) {
      case "bronze tier":
        return <StarFilled className="bronze-icon" />;
      case "silver tier":
        return <CrownFilled className="silver-icon" />;
      case "gold tier":
        return <RocketFilled className="gold-icon" />;
      case "platinum":
        return <ThunderboltFilled className="platinum-icon" />;
      case "black":
        return <EyeInvisibleFilled className="black-icon" />;
      default:
        return <EyeInvisibleFilled className="black-icon" />;
    }
  };

  const mappedCards = subscriptionPlans?.filter((plan) => plan.is_deleted === 0).map((plan) => {
      const price =selectedTab === "monthly" ? (plan.monthly_cost ? `$${plan.monthly_cost} | per month` : "Contact Us") : plan.yearly_cost ? `$${plan.yearly_cost} | per year` : "Contact Us";

      return {
        title: plan.name,
        color: getCardColor(plan.name),
        icon: getCardIcon(plan.name),
        description: plan.benefits,
        price: price,
        buttonText: "Edit Details",
        referenceNumber: plan.reference_number,
        entry:plan.entry
      };
    });

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCreateCancel = () => {
    setIsCreateModalVisible(false);
    form.resetFields();
  };

  const handleCreateNewPlan = async (values) => {
    
    try {
      const isNameConflict = subscriptionPlans.some((plan) => plan.name.toLowerCase() === values.name.toLowerCase());

      if (isNameConflict) {
        message.error("A plan with this name already exists. Please choose a different name.");
        return;
      }

      const obj = {
        name: values.name,
        monthly_cost: values.monthly_cost.toString(),
        yearly_cost: values.yearly_cost.toString(),
        campaign_specific_price: "CAMPAIGN SPECIFIC PRICE Monthly/Yearly",
        entry: values.entry,
        benefits: values.benefits,
        credits_per_action: "2",
      };
      setIsCreateModalVisible(false);
      await dispatch(CreateSubscriptionPlan({ obj, dispatch })).unwrap();

      form.resetFields();
      dispatch(GetSubscriptionPlans({ dispatch }));
    } catch (error) {
      console.error("Failed to create plan:", error);
    }
  };

  return (
    <div className="subscription-container">
      <h1 className="subscription-heading">Subscription Plans</h1>

      <Button type="primary" onClick={showCreateModal} className="create-plan-button">
        Create New Plan
      </Button>

      <Tabs defaultActiveKey="monthly" centered onChange={(key) => setSelectedTab(key)} className="subscription-tabs">
        <TabPane tab="Monthly" key="monthly" />
        <TabPane tab="Yearly" key="yearly" />
      </Tabs>

      <div className="card-container">
        {mappedCards.map((card, index) => {
          if (card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" && !showBlackCard) {
            return null;
          }

          const plan = subscriptionPlans?.find((p) => p.name === card.title);

          return (
            <Card key={index} hoverable className={`subscription-card ${card.title.toLowerCase()}-card`} style={{ background: card.color }}>
              <div className="card-icon">{card.icon}</div>
              <Meta
                title={<span className={card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "card-text_Black" : "card-title"}>{card.title}</span>}
                description={
                  <div className={card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "card-text_Black" : "card-description"}>
                    <p className={card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "card-text_Black" : "card-text"}>{card.description}</p>
                    <p className={card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "card-text_Black" : "card-price"}>{card.price}</p>
                    <p className={card.referenceNumber === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7" ? "card-text_Black" : "card-price"}>{card.entry}</p>
                    <div className="card-buttons">
                      <Button type="primary" className={`card-button ${card.title.toLowerCase()}-button`} onClick={() => handleEditClick(plan)}>
                        {card.buttonText}
                      </Button>
                      <Button color="danger" variant="solid" className={`card-button ${card.title.toLowerCase()}-button`} onClick={() => handleDeleteClick(card.referenceNumber)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                }
              />
            </Card>
          );
        })}
      </div>

      {subscriptionPlans?.some((ele) => ele.reference_number === "ST_fb42e71c-e4be-4b6a-8ee9-11ac29b219a7") && (
        <Button type="primary" className="toggle-black-card" onClick={() => setShowBlackCard((prev) => !prev)}>
          {showBlackCard ? "Hide Black Card" : "Reveal Black Card"}
        </Button>
      )}

      <Modal
        title="Create New Subscription Plan"
        visible={isCreateModalVisible}
        onCancel={handleCreateCancel}
        footer={[
          <Button key="cancel" onClick={handleCreateCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleCreateNewPlan}>
          <Form.Item
            name="name"
            label="Plan Name"
            rules={[
              { required: true, message: "Please input the plan name!" },
              {
                validator: (_, value) => {
                  if (subscriptionPlans.some((plan) => plan.name.toLowerCase() === value.toLowerCase())) {
                    return Promise.reject("A plan with this name already exists.");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="monthly_cost" label="Monthly Cost" rules={[{ required: true, message: "Please input the monthly cost!" }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="yearly_cost" label="Yearly Cost" rules={[{ required: true, message: "Please input the yearly cost!" }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="benefits" label="Benefits" rules={[{ required: true, message: "Please input the benefits!" }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="entry" label="Entry" rules={[{ required: true, message: "Please input the credits!" }]}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Subscription Plan"
        visible={isEditModalVisible}
        onCancel={handleCancelEdit}
        footer={[
          <Button key="cancel" onClick={handleCancelEdit}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => editForm.submit()}>
            Save
          </Button>,
        ]}
      >
        <Form form={editForm} onFinish={handleEditSubmit}>
          <Form.Item name="reference_number" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Plan Name"
            rules={[
              { required: true, message: "Please input the plan name!" },
              {
                validator: (_, value) => {
                  if (subscriptionPlans.some((plan) => plan.name.toLowerCase() === value.toLowerCase() && plan.reference_number !== editPlan.reference_number)) {
                    return Promise.reject("A plan with this name already exists.");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="monthly_cost" label="Monthly Cost" rules={[{ required: true, message: "Please input the monthly cost!" }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="yearly_cost" label="Yearly Cost" rules={[{ required: true, message: "Please input the yearly cost!" }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="benefits" label="Benefits" rules={[{ required: true, message: "Please input the benefits!" }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="entry" label="Entry" rules={[{ required: true, message: "Please input the credits!" }]}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Confirm Delete"
        visible={isDeleteModalVisible}
        onCancel={handleCancelDelete}
        footer={[
          <Button key="cancel" onClick={handleCancelDelete}>
            Cancel
          </Button>,
          <Button key="delete" variant="solid" color="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this subscription plan?</p>
      </Modal>
    </div>
  );
};

export default Subscription;
