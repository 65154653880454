import React, { useEffect, useState } from "react";
import { Table, Alert, Input, DatePicker } from "antd";
import axios from "axios";

const { Search } = Input;
const { RangePicker } = DatePicker;

const decodeFunctionName = (inputData) => {
  if (!inputData) return "";

  if (inputData.startsWith("0xfd336598")) {
    return "mint";
  }
  if (inputData.startsWith("0xcc3ec70d")) {
    return "profileUpdate";
  }
  return "Unknown Function";
};

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [error, setError] = useState(null);

  const columns = [
    {
      title: "Transaction Hash",
      dataIndex: "hash",
      key: "hash",
      render: (text) => (
        <a href={`https://testnet.bscscan.com/tx/${text}`} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Type",
      dataIndex: "functionName",
      key: "functionName",
    },
    {
      title: "Timestamp",
      dataIndex: "timeStamp",
      key: "timeStamp",
      render: (timestamp) => new Date(timestamp * 1000).toLocaleDateString(),
    },
  ];

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get("https://api-testnet.bscscan.com/api", {
          params: {
            module: "account",
            action: "txlist",
            address: "0x67c58470EDDe35A622b62E6C82b57De747FF6101",
            apikey: "CA4EY5IERE8BHIM58UE77KKPQRBK5S7FUZ",
          },
        });

        if (response.data.status === "1") {
          const updatedTransactions = response.data.result.map((tx, index) => ({
            ...tx,
            key: index,
            functionName: decodeFunctionName(tx.input),
          }));
          setTransactions(updatedTransactions);
          setFilteredTransactions(updatedTransactions);
        } else {
          setError(response.data.message || "No transactions found.");
        }
      } catch (err) {
        setError(err.message || "Failed to fetch transaction history.");
      }
    };

    fetchTransactions();
  }, []);

  const handleSearch = (value) => {
    const filteredData = transactions.filter((tx) => tx.functionName.toLowerCase().includes(value.toLowerCase()) || tx.from.toLowerCase().includes(value.toLowerCase()));
    setFilteredTransactions(filteredData);
  };

  const handleDateFilter = (dates) => {
    if (!dates || dates.length !== 2) {
      setFilteredTransactions(transactions);
      return;
    }

    const [start, end] = dates;
    const startDate = start.startOf("day").valueOf();
    const endDate = end.endOf("day").valueOf();

    const filteredData = transactions.filter((tx) => {
      const txDate = tx.timeStamp * 1000;
      return txDate >= startDate && txDate <= endDate;
    });

    setFilteredTransactions(filteredData);
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Transaction History</h2>

      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <Search
          placeholder="Filter by type and address(e.g., mint, profileUpdate and address)"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={handleSearch}
          style={{ maxWidth: 400 }}
        />
        <RangePicker format="YYYY-MM-DD" onChange={handleDateFilter} size="large" />
      </div>

      {error ? (
        <Alert message="Error" description={error} type="error" showIcon />
      ) : (
        <Table
          columns={columns}
          dataSource={filteredTransactions?.slice(1)?.map((tx, index) => ({
            ...tx,
            key: index,
          }))}
          scroll={{ y: 400 }}
        />
      )}
    </div>
  );
};

export default TransactionHistory;
