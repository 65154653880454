import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SecretKey;

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const ENCRYPTION_KEY = SECRET_KEY?.padEnd(32, "0").slice(0, 32);

export const SetEncryptEmailformat = (val) => {
  const email = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = localStorage?.setItem("email", email);
  return returnData;
};

export const SetUserChatHistory = (val) => {
  const returnData = localStorage?.setItem("ChatHistory", val);
  return returnData;
};

export const SetEncrypytUID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();
};

export const SetEncrypytPID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();
};

export const SetEncrypytInstagramUID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();
};

export const SetEncrypytInstagramPID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();
};

export const SetEncryptReferenceNumberformat = (val) => {
  const reference_number = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = localStorage?.setItem("reference_number", reference_number);
  return returnData;
};

export const SetEncryptAccessTokenformat = (val) => {
  const access_token = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = localStorage?.setItem("access_token", access_token);
  return returnData;
};

export const SetEncryptUniqId = (val) => {
  const uniqId = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = localStorage?.setItem("uniqId", uniqId);
  return returnData;
};

export const SetEncryptUserNameformat = (val) => {
  const instagramUserName = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = sessionStorage?.setItem("username", instagramUserName);
  return returnData;
};

export const SetUserData = (val) => {
  return localStorage?.setItem("UserName", val);
};

export const SetEncryptPasswordformat = (val) => {
  const instagramPassword = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = sessionStorage?.setItem("password", instagramPassword);
  return returnData;
};

export const SetEncryptTokenID = (val) => {
  const TokenID = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  return TokenID;
};

export const GetDecryptEID = (val) => {
  if (!val) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      return null;
    }

    return originalEmail;
  } catch (error) {
    return null;
  }
};

export const GetDecryptUID = (val) => {
  if (!val) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      return null;
    }

    return originalEmail;
  } catch (error) {
    return null;
  }
};

export const GetDecryptInstagramEID = (val) => {
  if (!val) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      return null;
    }

    return originalEmail;
  } catch (error) {
    return null;
  }
};

export const GetDecryptInstagramUID = (val) => {
  if (!val) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      return null;
    }

    return originalEmail;
  } catch (error) {
    return null;
  }
};

export const GetDecryptEmailformat = () => {
  const getEmail = localStorage?.getItem("email");

  if (!getEmail) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getEmail, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      return null;
    }

    return originalEmail;
  } catch (error) {
    return null;
  }
};

export const GetDecryptUniqId = () => {
  const getUniqId = localStorage?.getItem("uniqId");

  if (!getUniqId) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getUniqId, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalUniqId = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalUniqId) {
      return null;
    }

    return originalUniqId;
  } catch (error) {
    return null;
  }
};

export const GetDecryptReferenceNumberformat = () => {
  const getReferenceNumber = localStorage?.getItem("reference_number");

  if (!getReferenceNumber) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getReferenceNumber, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalReferenceNumber = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalReferenceNumber) {
      return null;
    }

    return originalReferenceNumber;
  } catch (error) {
    return null;
  }
};

export const GetDecryptAccessTokenformat = () => {
  const getAccessToken = localStorage?.getItem("access_token");

  if (!getAccessToken) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getAccessToken, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalAccessToken = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalAccessToken) {
      return null;
    }

    return originalAccessToken;
  } catch (error) {
    return null;
  }
};

export const GetDecryptUserNameformat = () => {
  const getInstagramUserName = sessionStorage?.getItem("username");

  if (!getInstagramUserName) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getInstagramUserName, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalInstagramUserName = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalInstagramUserName) {
      return null;
    }

    return originalInstagramUserName;
  } catch (error) {
    return null;
  }
};

export const GetDecryptPasswordformat = () => {
  const getInstagramPassword = sessionStorage?.getItem("password");

  if (!getInstagramPassword) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getInstagramPassword, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalInstagramPassword = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalInstagramPassword) {
      return null;
    }

    return originalInstagramPassword;
  } catch (error) {
    return null;
  }
};

export const EncryptDataAdmin = (val) => {
  const EncryptData = CryptoJS.AES.encrypt(val, ENCRYPTION_KEY)?.toString();
  return EncryptData;
};

export const DecryptedDataAdmin = (val) => {
  if (!val) {
    console.error("Error");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, ENCRYPTION_KEY);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalResponseData = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalResponseData) {
      return null;
    }

    let parseData = JSON.parse(originalResponseData);

    return parseData;
  } catch (error) {
    return null;
  }
};

export const DecryptedDataAdminlocalStorage = (val) => {
  if (!val) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, ENCRYPTION_KEY);

    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }

    const originalResponseData = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalResponseData) {
      return null;
    }

    return originalResponseData;
  } catch (error) {
    return null;
  }
};

export const AdminDashboardGraph = (val) => {
  if (!val) {
    console.error("Error");
    return null;
  }
  try {
    const bytes = CryptoJS.AES.decrypt(val, ENCRYPTION_KEY);
    if (!bytes || bytes.sigBytes <= 0) {
      return null;
    }
    const originalResponseData = bytes.toString(CryptoJS.enc.Utf8);
    if (!originalResponseData) {
      return null;
    }
    let parseData = JSON.parse(originalResponseData);
    return parseData;
  } catch (error) {
    return null;
  }
};
