import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserTawkQuery, SetUserChatLists, ticketlisting } from "../../redux/auth/authSlice";
import { Button, Table, Tooltip, Modal, Input } from "antd";
import moment from "moment/moment";
import "../../style/admin.css";

const { Search } = Input;

const UserChat = () => {
  const dispatch = useDispatch();
  const { userChatLists } = useSelector((state) => state.counter);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [queryIdToDelete, setQueryIdToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(ticketlisting());
  }, [dispatch]);

  const handleDeleteClick = (queryId) => {
    setQueryIdToDelete(queryId);
    setIsModalVisible(true);
  };

  const handleDelete = () => {
    dispatch(deleteUserTawkQuery({ queryId: queryIdToDelete, dispatch }));
    const updatedUserChatLists = userChatLists.filter((user) => user.id !== queryIdToDelete);
    dispatch(SetUserChatLists(updatedUserChatLists));
    setIsModalVisible(false);
    setQueryIdToDelete(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const filteredData = userChatLists.filter((user) => user.sender.toLowerCase().includes(searchQuery.toLowerCase()));

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("YYYY-MM-DD"),
    },
    {
      title: "Name",
      dataIndex: "sender",
      key: "sender",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Query",
      dataIndex: "message",
      key: "message",
      render: (message) => (
        <Tooltip title={message}>
          <div className="message-ellipsis">{message}</div>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" danger onClick={() => handleDeleteClick(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <h2>User Query</h2>

      <Search placeholder="Search by Name" value={searchQuery} onChange={(e) => handleSearch(e.target.value)} style={{ width: 300, marginBottom: 20 }} />

      <Table dataSource={filteredData} columns={columns} rowKey="id" />

      <Modal title="Confirm Deletion" visible={isModalVisible} onOk={handleDelete} onCancel={handleCancel} okText="Yes" cancelText="No" destroyOnClose={true}>
        <p>Are you sure you want to delete this query?</p>
      </Modal>
    </div>
  );
};

export default UserChat;
