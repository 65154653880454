import React from "react";
import "../../../App.css";
import { Link, useLocation } from "react-router-dom";

import { FaTachometerAlt, FaReceipt, FaServer, FaUsers, FaQuestionCircle, FaMoneyCheckAlt, FaCaretLeft, FaCreditCard, FaRocketchat, FaDatabase, FaGuilded, FaStripeS } from "react-icons/fa";

import ProjectWlogo from "../../../Utils/Images/ProjectWlogo.svg";

const Sidebar = ({ HandleToggle }) => {
  const pathName = useLocation();

  return (
    <>
      <div className="side-bar">
        <a className="abs-bk" onClick={HandleToggle}>
          <FaCaretLeft className="icon" />
        </a>
        <div className="logo-sec">
          <Link to="/admindashboard">
            <img className="w-100 logo-top" src="images/logo.png" alt="" />
            <h1
              style={{
                color: "white",
                textAlign: "center",
                fontFamily: "serif",
                fontWeight: "600",
              }}
            >
              <img src={ProjectWlogo} alt="Logo" className="logo" />
            </h1>
          </Link>
        </div>
        <div className="link-div">
          <ul className="link-ul">
            <li>
              <Link to="/admindashboard" className={pathName.pathname === "/admindashboard" ? "active" : ""}>
                <FaTachometerAlt className="icons-svg" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link to="/billingstatistics" className={pathName.pathname === "/billingstatistics" ? "active" : ""}>
                <FaReceipt className="icons-svg" />
                Billing Status
              </Link>
            </li>
            <li>
              <Link to="/apistatus" className={pathName.pathname === "/apistatus" ? "active" : ""}>
                <FaServer className="icons-svg" />
                API Status
              </Link>
            </li>
            <li>
              <Link to="/UserDetails" className={pathName.pathname === "/UserDetails" ? "active" : ""}>
                <FaUsers className="icons-svg" />
                User Details
              </Link>
            </li>
            <li>
              <Link to="/faq" className={pathName.pathname === "/faq" ? "active" : ""}>
                <FaQuestionCircle className="icons-svg" />
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/transaction" className={pathName.pathname === "/transaction" ? "active" : ""}>
                <FaMoneyCheckAlt className="icons-svg" />
                Transactions
              </Link>
            </li>
            <li>
              <Link to="/Subscription" className={pathName.pathname === "/Subscription" ? "active" : ""}>
                <FaCreditCard className="icons-svg" />
                Subscription
              </Link>
            </li>
            <li>
              <Link to="/setupstripe" className={pathName.pathname === "/setupstripe" ? "active" : ""}>
                <FaStripeS className="icons-svg" />
                Setup Stripe
              </Link>
            </li>
            <li>
              <Link to="/userChat" className={pathName.pathname === "/userChat" ? "active" : ""}>
                <FaRocketchat className="icons-svg" />
                User Tickets
              </Link>
            </li>
            <li>
              <Link to="/guide" className={pathName.pathname === "/guide" ? "active" : ""}>
                <FaGuilded className="icons-svg" />
                Guide
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
