import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../../style/admin.css";
import { AdminLogin, GetProfileAdmin } from "../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { DecryptedDataAdminlocalStorage, EncryptDataAdmin } from "../Common/Common";
import Loader from "../../Components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const { userRole, isLoading } = useSelector((auth) => auth.counter);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const onFinish = (values) => {
    let obj = {
      email: values?.email,
      password: values?.password,
    };

    let encBody = EncryptDataAdmin(JSON.stringify(obj));

    let objData = {
      enc: encBody,
    };

    if (parseInt(userCaptchaAnswer) !== captchaAnswer) {
      setCaptchaError("CAPTCHA is incorrect.");
      return;
    }

    dispatch(AdminLogin({ objData, dispatch, navigate }));
  };

  useEffect(() => {
    let obj = {
      reference_number: DecryptedDataAdminlocalStorage(localStorage.getItem("reference_number")),
    };

    let encBody = EncryptDataAdmin(JSON.stringify(obj));

    let objData = {
      enc: encBody,
    };

    dispatch(GetProfileAdmin({ objData, dispatch }));
    if (localStorage.getItem("access_token") && userRole === "USER") {
      navigate("/dashboard");
    } else if (localStorage.getItem("access_token") && userRole === "admin") {
      navigate("/admindashboard");
    }
  }, []);

  const [captchaQuestion, setCaptchaQuestion] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [userCaptchaAnswer, setUserCaptchaAnswer] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const generateCaptcha = () => {
    let num1 = Math.floor(Math.random() * 10) + 1;
    let num2 = Math.floor(Math.random() * 10) + 1;
    const operations = ["+", "-", "*", "/"];
    const operation = operations[Math.floor(Math.random() * operations.length)];

    let question;
    let answer;

    switch (operation) {
      case "+":
        question = `What is ${num1} + ${num2}?`;
        answer = num1 + num2;
        break;
      case "-":
        question = `What is ${num1} - ${num2}?`;
        answer = num1 - num2;
        break;
      case "*":
        question = `What is ${num1} * ${num2}?`;
        answer = num1 * num2;
        break;
      case "/":
        num2 = num2 === 0 ? 1 : num2;
        num1 = num1 - (num1 % num2);
        question = `What is ${num1} / ${num2}?`;
        answer = num1 / num2;
        break;
      default:
        break;
    }

    setCaptchaQuestion(question);
    setCaptchaAnswer(answer);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleCaptchaChange = (e) => {
    const value = e.target.value;
    if (/^-?\d*$/.test(value)) {
      setUserCaptchaAnswer(value);
      setCaptchaError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div
        className="signuppage mt-5 bg-light text-black text-v p-5 resp_otp_css otp_custom_css admin_login_css"
        style={{ maxWidth: "400px", margin: "auto", padding: "20px", textAlign: "center" }}
      >
        <Title level={4}>Admin Login</Title>

        <Form name="trouble_logging_in" layout="vertical" onFinish={onFinish} style={{ marginTop: "20px" }}>
          <Form.Item
            label={
              <span>
                Email <span style={{ color: "red" }}>*</span>
              </span>
            }
            required={false}
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              {
                type: "email",
                message: "Invalid email address",
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label={
              <span>
                Password <span style={{ color: "red" }}>*</span>
              </span>
            }
            required={false}
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
              {
                min: 6,
                message: "Password must be at least 6 characters long",
              },
            ]}
          >
            <div style={{ position: "relative" }}>
              <Input type={showPassword ? "text" : "password"} placeholder="Enter your password" style={{ paddingRight: "30px" }} />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#4b49ac",
                }}
              />
            </div>
          </Form.Item>

          <Form.Item label="Please verify you are a human" help={captchaError} validateStatus={captchaError ? "error" : ""} style={{ textAlign: "left" }}>
            <Text>{captchaQuestion}</Text>
            <Input value={userCaptchaAnswer} onChange={handleCaptchaChange} placeholder="Enter CAPTCHA answer" />
          </Form.Item>

          <Form.Item>
            <Button style={{ marginTop: "10px" }} type="primary" htmlType="submit" block size="large">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ForgotPassword;
