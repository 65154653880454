import React from "react";
import "antd/dist/reset.css";
import { Typography } from "antd";

const { Title, Text, Paragraph } = Typography;

const Guide = () => {
  return (
    <div className="container py-4 bg-white">
      <Title level={1} className="text-center mb-4">
        Tawk.to Guide
      </Title>

      <section className="mb-4">
        <Title level={2}>Accessing the Tawk.to Admin Dashboard</Title>
        <Paragraph>To manage chat settings and monitor conversations, you must log into the Admin Dashboard.</Paragraph>
        <ol>
          <li>
            <Text>
              Go to the{" "}
              <a href="https://dashboard.tawk.to" className="text-primary">
                Tawk.to website
              </a>
              .
            </Text>
          </li>
          <li>
            <Text>Log in with your credentials by entering your email and password, then click Log in.</Text>
          </li>
          <li>
            <Text>Select the correct property if you manage multiple websites.</Text>
          </li>
        </ol>
      </section>

      <section className="mb-4">
        <Title level={2}>How to Create a User in the Admin Dashboard</Title>
        <Paragraph>Adding users allows team members to respond to chats, manage tickets, and access the dashboard.</Paragraph>
        <ol>
          <li>
            <Text>Navigate to User Management by clicking on ⚙️ Settings (gear icon) in the sidebar.</Text>
          </li>
          <li>
            <Text>
              Select <strong>User Management</strong> from the menu.
            </Text>
          </li>
          <li>
            <Text>
              Click the <strong>Invite Member</strong> button and enter the email address of the person you want to add.
            </Text>
          </li>
          <li>
            <Text>Assign a Role:</Text>
            <ul>
              <li>
                <Text>
                  <strong>Admin</strong> – Full access to all settings and data.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Agent</strong> – Can answer chats and view dashboards but has limited access to settings.
                </Text>
              </li>
            </ul>
          </li>
          <li>
            <Text>
              Click <strong>Send Invitation</strong>. The invited user will receive an email with a link to join.
            </Text>
          </li>
          <li>
            <Text>The new user must accept the invitation and log in with their credentials.</Text>
          </li>
        </ol>
      </section>

      <section className="mb-4">
        <Title level={2}>Available Features in the Tawk.to Admin Dashboard</Title>
        <Paragraph>The Admin Dashboard provides multiple tools to manage customer interactions efficiently.</Paragraph>
        <ul>
          <li>
            <Text>✅ Live Chat Monitoring – Track active visitors and ongoing conversations.</Text>
          </li>
          <li>
            <Text>✅ Inbox & Ticketing – Manage missed chats, offline messages, and support tickets.</Text>
          </li>
          <li>
            <Text>✅ User Management – Add, remove, and manage team members with different access levels.</Text>
          </li>
          <li>
            <Text>✅ Analytics & Reporting – View chat history, visitor insights, and performance metrics.</Text>
          </li>
          <li>
            <Text>✅ Chat Widget Customization – Modify the chatbox design, triggers, and welcome messages.</Text>
          </li>
          <li>
            <Text>✅ Automated Responses – Create predefined responses to improve efficiency.</Text>
          </li>
          <li>
            <Text>✅ Email & Notifications – Configure email alerts for new chats and support requests.</Text>
          </li>
          <li>
            <Text>✅ Integrations – Connect Tawk.to with CRM tools, Slack, and other third-party apps.</Text>
          </li>
        </ul>
      </section>

      <section>
        <Title level={2}>Embedding Tawk.to via an Iframe on the Admin Dashboard</Title>
        <Paragraph>You can integrate Tawk.to into your custom Admin Dashboard using an iframe.</Paragraph>
        <ol>
          <li>
            <Text>Get the Direct Chat Link:</Text>
            <ul>
              <li>
                <Text>Go to ⚙️ Settings &gt; Channels &gt; Chat Widget.</Text>
              </li>
              <li>
                <Text>
                  Copy the Direct Chat Link (e.g., <code>https://tawk.to/chat/your-widget-id/default</code>).
                </Text>
              </li>
            </ul>
          </li>
          <li>
            <Text>Add an Iframe to Your Admin Panel:</Text>
          </li>
        </ol>
        <iframe src="https://tawk.to/chat/66fa5521e5982d6c7bb68359/1i90tp3i2" width="100%" height="600px" style={{ border: "none" }} title="Tawk.to Chat"></iframe>
        <ol>
          <li>
            <Text>
              Replace <code>your-widget-id</code> with your actual Tawk.to widget ID.
            </Text>
          </li>
          <li>
            <Text>Test the Integration by opening the Admin Dashboard and checking if the Tawk.to chat appears inside the iframe.</Text>
          </li>
        </ol>
      </section>
    </div>
  );
};

export default Guide;
